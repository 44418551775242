* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Samarkan";
  src: url("./assets/fonts/samarkan-cufonfonts-webfont/SAMARN__.woff")
      format("woff2"),
    url("./assets/fonts/samarkan-cufonfonts-webfont/SAMARO__.woff")
      format("woff");
}

body {
  background-color: #000;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

.cusfont {
  font-family: "Samarkan" !important;
}

.btn-pri {
  background-color: #3671e9;
  color: #fff;
  border-radius: 10px;
}

.btn-cus {
  background: #b85042;
  color: #fff;
  border-radius: 3px;
  box-shadow: -11.09px 18.13px 36.6483px rgba(242, 153, 74, 0.13);
}

.mt-h {
  margin-top: 7rem;
}

@media only screen and (max-width: 600px) {
  .rmt-1 {
    margin-top: 1rem;
  }
  .rmt-2 {
    margin-top: 2rem;
  }
  .rmt-3 {
    margin-top: 3rem;
  }
  .rmt-5 {
    margin-top: 5rem;
  }
  .rtext-center {
    text-align: center;
  }
}
