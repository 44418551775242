.showcase {
  background-image: url("../../assets/images/bg.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .cus-nav {
    background-color: transparent;
    color: #fff;
    .si {
      height: 35px;
      margin: 0 0.5rem;
    }

    .link-style {
      color: #fff !important;
      margin-left: 1rem;
      font-weight: bold;
    }
    .logo {
      height: 80px;
    }
  }

  .showcase-content {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);

    height: 84vh;

    .showcase-title {
      font-size: 5rem;
      font-weight: bold;
      color: orange;
      text-align: center;
    }

    .tit {
      color: white;
      font-weight: bold;
    }
    .showlogo {
      height: 50px;
    }
  }

  .linkh {
    color: #666;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
  }
  .linkh:after {
    display: block;
    content: "";
    border-bottom: solid 3px #fff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }
  .linkh:hover:after {
    transform: scaleX(1);
  }
  .linkh.fromRight:after {
    transform-origin: 100% 50%;
  }
  .linkh.fromLeft:after {
    transform-origin: 0% 50%;
  }
}

.expicon {
  height: 30px;
}

.road {
  height: 300px;
}

.text-pri {
  color: #fff !important;
}

.title {
  font-weight: bold;
  font-size: 2.6rem;
  color: #fff;
}

.subtitle {
  font-size: 1rem;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.about {
  margin-top: 10rem;

  .about-img {
    height: 500px;
  }
}

.slider {
  margin-top: 10rem;
  .slider-div {
    margin: 0 !important;
    padding: 0 !important;
    .slider-img {
      height: 285px;
    }
  }
}

.dracoexp {
  margin-top: 10rem;

  .con-1 {
    margin-top: 5rem;
  }
  .exp-title {
    font-size: 1.1rem;
    font-weight: 900;
  }
  .exp-subtitle {
    // font-weight: 900;
    color: #fff;
    line-height: 22px;
    font-size: 0.9rem;
  }

  .p-title {
    font-size: 2.25rem;
    color: #fff;
    font-weight: bold;
  }
  .p-subtitle {
    font-size: 1rem;
    color: #fff;
    font-weight: 900;
  }
}

.content-title {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap {
  margin-top: 10rem;

  .number-text {
    font-size: 1.5rem;
  }
}

.team {
  margin-top: 10rem;

  .team-img {
    height: 400px;
    border-radius: 50%;
  }
  .social-icon {
    height: 35px;
  }
}

.faq {
  margin-top: 5rem;

  .con-bot {
    // font-weight: 500;
    color: #fff;
    font-size: 1.6rem !important;
  }
}

.footer {
  // background-image: url("../../assets/img/Group.png");

  .footer-text {
    font-weight: bold;
  }
}

.plus-img {
  height: 60px;
  cursor: pointer;
}

.cusbtn {
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  padding: 0.2rem 1.5rem;
  border: 3px solid #fff;
  font-weight: bold;
  border-radius: 20px;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
}

.cus-btn {
  background-color: #000;
  color: #fff;
  font-size: 1.4rem;
  padding: 0.5rem 1.5rem;
  border: 3px solid #fff;
  font-weight: bold;
  border-radius: 20px;
  letter-spacing: 3px;
}

.cusbtn:hover {
  background-color: #928058;
  color: #000 !important;
}
.cus-btn:hover {
  background-color: #fff;
  color: #000 !important;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}
.display-number {
  color: #fff;
  height: 70px;
  width: 70px;
}
.number {
  font-weight: bold;
  font-size: 3rem;
}

.mintbox {
  border: 3px solid #ccc;
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .slider-img {
    height: 60.5px;
  }
  .about-img {
    height: auto !important;
  }

  .rmt {
    margin-top: 5rem;
  }

  .countd {
    align-items: center !important;
  }

  .showcase-title {
    font-size: 1rem !important;
  }

  .showcase-subtitle {
    font-size: 1rem !important;
  }
  .number-text {
    font-size: 1.5rem !important;
  }

  .showcase {
    height: auto !important;
    .showcase-content {
      height: auto !important;
      margin-top: 2rem !important;

      .mbcon {
        margin-top: 2rem !important;
      }
    }
  }

  .team-img {
    height: auto !important;
  }
}
